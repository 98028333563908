import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { EnvironmentService } from '@core/domains/environment/environment.service';


@Injectable()
export class ResetPasswordEmailService {
  baseUrl: string;

  constructor(
    private envService: EnvironmentService,
    private http: HttpClient,
  ) {
    this.baseUrl = `${this.envService.environment.api_domain}/api/auth`;
  }

  sendPasswordResetEmail(email: string):  Observable<unknown> {
    const body = {'email': email};
    return this.http.post(`${this.baseUrl}/admins/awareness/reset_password/`, body);
  }
}
