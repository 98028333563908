

<ng-container *transloco="let t; read: 'auth.LOGIN'">

  <!-- <div class="background">
    <div class="cube"></div>
    <div class="cube2"></div>
  </div> -->
  <div class="full-height">
    <div class="logo"></div>
    <div class="center-section">
      <div class="login-container">
        <section class="first-login">
          <header>
            <h4>{{ t('first-connexion') }}</h4>
          </header>
          <article>
            <p>{{ t('walkthrough') }}</p>
          </article>
          <button mat-flat-button [routerLink]="['/auth/signup']" color="primary">{{ t('active-licence') }}</button>
        </section>
        <section class="main-login">
          <header>
            <h4>{{ t('connection') }}</h4>
          </header>
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" style="display:contents;">
            <article class="form">
              <div style="display:contents;">
                <mat-form-field>
                  <mat-label>{{ t('email') }}</mat-label>
                  <input matInput type="email" name="email" formControlName="email" placeholder="{{ t('email') }}">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ t('password') }}</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" name="password" formControlName="password" placeholder="{{ t('password') }}">
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <div class="error-container">
                  <div *ngIf="submitted && registerForm.get('email')?.errors">
                    <span *ngIf="registerForm.get('email')?.hasError('required')">{{ t('form_errors.email') }}</span>
                  </div>
                  <div *ngIf="submitted && registerForm.get('password')?.errors">
                    <span *ngIf="registerForm.get('password')?.hasError('required')">{{ t('form_errors.password') }}</span>
                  </div>
                  <span *ngIf="!submitted && errorMessage">
                    <span>{{errorMessage}}</span>
                  </span>
                </div>
              </div>
            </article>
            <footer class="actions login">
              <p>
                <a [routerLink]="['/auth/reset_password/email']" [queryParams]="{origin: origin}">{{ t('reset') }}</a>
              </p>
              <button mat-flat-button class="withSpinner" color="accent" type="submit" [disabled]="registerForm.invalid && registerForm.dirty">
                <span *ngIf="!loading">{{ t('submit') }}</span>
                <mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
              </button>
            </footer>
          </form>
        </section>
      </div>
      <div class="contact">
        <a href="https://www.olfeo.com/contrats-et-cgv/" class="contact-button">{{ t('contact') }}</a>
      </div>
    </div>
  </div>
</ng-container>
