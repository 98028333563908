import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';

import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoginService } from '@core/auth/login.service';

@Component({
  selector: 'olfeo-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth'} }]
})
export class LoginComponent implements OnInit {
  origin: string = window.location.origin;
  errorMessage: string;
  handset: boolean;
  submitted = false;
  loading = false;
  registerForm: UntypedFormGroup;
  hide = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private srv: LoginService,
    private router: Router,
    public breakpoint: BreakpointObserver,
    public loco: TranslocoService
  ) {
    this.breakpoint.observe(['(max-width: 812px)']).pipe(
      tap((state: BreakpointState) => this.handset = state.matches)
    ).subscribe();
  }

  ngOnInit(): void {
    if (this.srv.isLoggedIn()) {
      this.router.navigate(['/']);
    }
    this.createForm();
  }

  createForm(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.loading = true;
    const email: string = this.registerForm.controls['email'].value as string;
    const password: string = this.registerForm.controls['password'].value as string;

    this.srv.onLogin(email, password).pipe(
      catchError(() => of(this.setErrors())),
      finalize(() => this.loading = false)
    ).subscribe({next: () => this.router.navigate(['/'])});
  }

  setErrors(): void {
    this.submitted = false;
    this.errorMessage = this.loco.translate('API_ERRORS.legacy.login');
  }
}
