
export const greetings = (): void => console.log(`

        __          __  _
        \\ \\        / / | |
         \\ \\  /\\  / /__| | ___ ___  _ __ ___   ___
          \\ \\/  \\/ / _ \\ |/ __/ _ \\| '_ \` _ \\ / _ \\
           \\  /\\  /  __/ | (_| (_) | | | | | |  __/
            \\/| \\/ \\___|_|\\___\\___/|_| |_| |_|\\___|
              | |_ ___
   ____  _  __| __/ _ \\        __          __     _____  ______ _   _ ______  _____ _____
  / __ \\| |/ _| || (_) |      /\\ \\        / /\\   |  __ \\|  ____| \\ | |  ____|/ ____/ ____|
 | |  | | | |_ \\__\\___/      /  \\ \\  /\\  / /  \\  | |__) | |__  |  \\| | |__  | (___| (___
 | |  | | |  _/ _ \\/ _ \\    / /\\ \\ \\/  \\/ / /\\ \\ |  _  /|  __| | . \` |  __|  \\___ \\\\___ \\
 | |__| | | ||  __/ (_) |  / ____ \\  /\\  / ____ \\| | \\ \\| |____| |\\  | |____ ____) |___) |
  \\____/|_|_| \\___|\\___/  /_/    \\_\\/  \\/_/    \\_\\_|  \\_\\______|_| \\_|______|_____/_____/



`)
