import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MatTableDataSource } from '@angular/material/table';

/**
 * Store for DataSource instance, and for filter's value
 */
@Injectable({
  providedIn: 'root'
})
export class DatasourceStoreService<T> {
  private _activeDatasource: BehaviorSubject<MatTableDataSource<T> | null> = new BehaviorSubject<MatTableDataSource<T> | null>(null);
  public activeDatasource: Observable<MatTableDataSource<T> | null> = this._activeDatasource.asObservable();

  private _searchterm: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public searchTerm: Observable<string | null> = this._searchterm.asObservable();

  set (datasource: MatTableDataSource<T>): void {
    this._activeDatasource.next(datasource);
  }

  updateSearchTerm (value: string): void {
    this._searchterm.next(value);
  }
}
