import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridComponent } from './grid.component';
import { DatasourceStoreService } from './datasource-store.service';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [GridComponent],
    exports: [GridComponent],
    providers: [DatasourceStoreService],
    imports: [
        CommonModule,
        RouterModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatIconModule,
        MatButtonModule,
    ]
})
export class GridModule { }
