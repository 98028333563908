import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ObtainTokensIO, AccessTokenIO } from '@core/auth/login.interfaces';
import { LoginService } from '@core/auth/login.service';
import { CAMPUS } from '@core/constants/products.constant';
import { AdminMeStoreService } from '@core/domains/adminMe/adminMe-store.service';
import { ADMIN_CONFIG, AdminToken, ProvideAppConf } from '@core/domains/administrator/administrator';
import { EnvironmentService } from '@core/domains/environment/environment.service';
import { WINDOW } from '@core/tools/window';
import { map, Observable, tap } from 'rxjs';

export const campusConfig : AdminToken = {
  api: "awareness",
  licence: CAMPUS
}

export const provideAppConf : ProvideAppConf = {
  provide: ADMIN_CONFIG,
  useValue: campusConfig
}

@Injectable()
export class CampusLoginService extends LoginService {

  constructor(
    protected override envService: EnvironmentService,
    @Inject(WINDOW) window: Window,
    protected override http: HttpClient,
    protected override router: Router,
    private adminStore: AdminMeStoreService
  ) {
    super(envService, window, http, router);
  }

  onLogin(email: string, password: string): Observable<ObtainTokensIO> {
    return this.http.post<ObtainTokensIO>(`${this.baseUrl}/token/obtain/`, {email, password}).pipe(
      tap((response: ObtainTokensIO) => this.setTokens(response))
    );
  }

  onLogout(): void {
    this.deleteTokens();
    this.adminStore.clearAdmin();
    this.router.navigate(['auth/login']);
  }

  refreshToken(): Observable<AccessTokenIO> {
    return this.http.post<AccessTokenIO>(`${this.baseUrl}/token/refresh/`, {
      refresh: this.storage.getItem('Refresh')
    }).pipe(
      tap(
        (response: AccessTokenIO) => {
          this.storage.setItem('AuthToken', response.access);
          this.setExpiry(response.access);
        }
      )
    );
  }

  verifyToken(): Observable<string | null>{
    const token = this.getAuthToken();
    return this.http.post<string | null>(`${this.baseUrl}/token/verify/`, {token}).pipe(
      map(() => token)
    )
  }
}
