import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AVALAIBLE_LANGUAGES_DEFINITIONS } from '@core/constants/available-languages.constant';
import { translocoInlineLoaderFactory } from '@core/tools/translocoInlineLoader.factory';
import { provideTranslocoScope } from '@jsverse/transloco';



const routes: Routes = [

  // Auth
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  // Layout (need authentication)
  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), providers: [
      provideTranslocoScope({
        scope: '', loader: translocoInlineLoaderFactory('../assets/i18n', AVALAIBLE_LANGUAGES_DEFINITIONS)
      })
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
