import { APP_INITIALIZER, ApplicationModule, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { ConsentBottomSheetModule } from '@core/ui-components/consent-bottom-sheet/consent-bottom-sheet.module';
import { AuthModule } from './auth/auth.module';
import { LoginService } from '@core/auth/login.service';
import { httpInterceptorsProviders } from './auth/interceptors/interceptor.index';
import { EnvironmentService } from '@core/domains/environment/environment.service';
import { Environment } from '@core/domains/environment/environment';
import { AdminMeStoreService } from '@core/domains/adminMe/adminMe-store.service';
import { AdminMeService } from '@core/domains/adminMe/adminMe.service';
import { CampusLoginService, provideAppConf } from './auth/campus-login.service';
import { GridModule } from '@core/ui-components/grid/grid.module';
import { MatomoConsentMode, MatomoInitializationMode, MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';

function preloadEnvironment(env: EnvironmentService) {
  return (): Promise<Environment> => {
    return env.initEnv();
  }
}
@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [ApplicationModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatomoRouterModule,
        RouterModule,
        AuthModule,
        MatomoModule.forRoot({
            mode: MatomoInitializationMode.AUTO_DEFERRED,
            disabled: window.location.host.includes('localhost'),
            requireConsent: MatomoConsentMode.TRACKING,
            // acceptDoNotTrack: true
        }),
        ConsentBottomSheetModule,
        MatSnackBarModule,
        GridModule,
        TranslocoRootModule], providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: preloadEnvironment,
            deps: [EnvironmentService]
        },
        EnvironmentService,
        AdminMeStoreService,
        provideAppConf,
        AdminMeService,
        { provide: LoginService, useClass: CampusLoginService },
        httpInterceptorsProviders,
        Title,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 4000, horizontalPosition: 'end', verticalPosition: 'top' }
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
